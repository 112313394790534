import anime from "animejs";

export default function () {
	const bannerAnimation = document.querySelector(".banner-animation");
	if (!bannerAnimation) return;

	const PHONE_WIDTH = 306;
	const PHONE_HEIGHT = 656;

	const homeNotifications = document.querySelectorAll(
		'div[class^="banner-animation__home-notification"]'
	);
	const animationConfirmation = document.querySelector(
		".banner-animation__confirmation"
	);

	const tl = anime.timeline({
		duration: 16000,
		easing: "easeInOutQuad",
		loop: true,
	});
	// Phone notifications
	tl.add({
		targets: homeNotifications,
		opacity: [0, 1],
		delay: anime.stagger(200),
		duration: 750,
	}).add(
		{
			targets: 'div[class*="__home-notification-"',
			opacity: [1, 0],
			duration: 600,
		},
		"+=1500"
	);

	// Confirmation & signature box
	tl.add({
		targets: ".banner-animation__confirmation",
		opacity: [0, 1],
		height: [
			`${(100 * animationConfirmation.offsetHeight) / PHONE_HEIGHT}%`,
			`${(100 * 179) / PHONE_HEIGHT}%`,
		],
		duration: 1000,
	})
		.add(
			{
				targets: ".banner-animation__home-notification",
				opacity: [1, 0],
				duration: 500,
			},
			"-=750"
		)
		.add({
			targets: ".banner-animation__confirmation img",
			opacity: [0, 1],
			duration: 1000,
		})
		.add(
			{
				targets: [
					".banner-animation__signature-text",
					".banner-animation__signature-box",
				],
				opacity: [0, 1],
				duration: 1000,
			},
			"-=1000"
		);
	// SVG Signature
	tl.add({
		targets: ".banner-animation__signature-box svg path:nth-child(2)",
		strokeDashoffset: [anime.setDashoffset, 0],
		duration: 1250,
		easing: "linear",
	});
	tl.add({
		targets: ".banner-animation__signature-box svg path:nth-child(1)",
		strokeDashoffset: [anime.setDashoffset, 0],
		duration: 1250,
		easing: "linear",
	});

	// Elements moving out from center
	const elementsDuration = 1000;
	tl.add(
		{
			targets: ".banner-animation__qr-code",
			opacity: [0, 1],
			translateX: ["120%", "0%"],
			translateY: ["120%", "0%"],
			translateZ: ["0px", "1px"],
			duration: elementsDuration,
		},
		"+=1000"
	)
		.add(
			{
				targets: ".banner-animation__calendar-container",
				opacity: [0, 1],
				translateX: ["100%", "0%"],
				translateY: ["-10%", "0%"],
				translateZ: ["0px", "1px"],
				duration: elementsDuration,
			},
			`-=${elementsDuration}`
		)
		.add(
			{
				targets: ".banner-animation__expenses",
				opacity: [0, 1],
				translateX: ["-100%", "0%"],
				translateY: ["-100%", "0%"],
				translateZ: ["0px", "1px"],
				duration: elementsDuration,
			},
			`-=${elementsDuration}`
		)
		.add(
			{
				targets: ".banner-animation__tapinout",
				opacity: [0, 1],
				translateX: ["-75%", "0%"],
				translateY: ["75%", "0%"],
				translateZ: ["0px", "1px"],
				duration: elementsDuration,
			},
			`-=${elementsDuration}`
		);
	// Expansion states
	tl.add(
		{
			targets: ".banner-animation__calendar-notification",
			opacity: [0, 1],
			translateY: ["-100%", "0%"],
			translateZ: ["0px", "1px"],
			duration: 750,
		},
		"+=1000"
	)
		.add(
			{
				targets: ".banner-animation__tapinout-collapsed",
				opacity: [1, 0],
				duration: 1250,
			},
			"-=750"
		)
		.add(
			{
				targets: ".banner-animation__tapinout-expanded",
				opacity: [0, 1],
				duration: 1250,
			},
			"-=1250"
		)
		.add(
			{
				targets: ".banner-animation__expenses",
				height: [
					`${(100 * 80) / PHONE_HEIGHT}%`,
					`${(100 * 197) / PHONE_HEIGHT}`,
				],
				duration: 750,
			},
			"-=1250"
		)
		.add(
			{
				targets: [
					".banner-animation__expenses-2",
					".banner-animation__expenses-3",
				],
				opacity: [0, 1],
				duration: 750,
				// delay: anime.stagger(100),
			},
			"-=1250"
		);
	// Retract all elements & fade
	const retractDuration = 1500;
	tl.add(
		{
			targets: ".banner-animation__qr-code",
			opacity: [1, 0],
			translateX: ["0%", "120%"],
			translateY: ["0%", "120%"],
			translateZ: ["0px", "1px"],
			duration: retractDuration,
		},
		"+=3000"
	)
		.add(
			{
				targets: ".banner-animation__calendar-container",
				opacity: [1, 0],
				translateX: ["0%", "100%"],
				translateY: ["0%", "-10%"],
				translateZ: ["0px", "1px"],
				duration: retractDuration,
			},
			`-=${retractDuration}`
		)
		.add(
			{
				targets: ".banner-animation__expenses",
				opacity: [1, 0],
				translateX: ["0%", "-100%"],
				translateY: ["0%", "-100%"],
				translateZ: ["0px", "1px"],
				duration: retractDuration,
			},
			`-=${retractDuration}`
		)
		.add(
			{
				targets: ".banner-animation__tapinout",
				opacity: [1, 0],
				translateX: ["0%", "-75%"],
				translateY: ["0%", "75%"],
				translateZ: ["0px", "1px"],
				duration: retractDuration,
			},
			`-=${retractDuration}`
		);

	// Fade out confirmation & signature box
	tl.add({
		targets: [
			".banner-animation__confirmation",
			".banner-animation__signature-text",
			".banner-animation__signature-box",
		],
		opacity: [1, 0],
		duration: 750,
		// Delay before start of next loop
		endDelay: 3000,
	});
}
