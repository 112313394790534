export default function () {
	const modalTriggers = document.querySelectorAll(".js-modalTrigger");
	const HIDDEN_CLASS = "modal--hidden";
	const OVERLAY_CLASS = "overlay";

	function openModal(modal) {
		modal.classList.remove(HIDDEN_CLASS);
	}

	function closeModal(modal) {
		modal.classList.add(HIDDEN_CLASS);
	}

	modalTriggers.forEach((trigger) => {
		const triggerTargetId = trigger.dataset.modalTarget;
		const modal = document.getElementById(triggerTargetId);

		if (!triggerTargetId || !modal) return;

		trigger.addEventListener("click", function () {
			openModal(modal);
		});

		modal.addEventListener("click", function (e) {
			// Close if click on background
			if (e.target.classList.contains(OVERLAY_CLASS)) {
				closeModal(modal);
			}
		});

		const submitButton = modal.querySelector('button[type="submit"]');
		if (submitButton) {
			submitButton.addEventListener("click", function () {
				closeModal(modal);
			});
		}

		const closeButton = modal.querySelector(".js-closeModal");
		if (closeButton) {
			closeButton.addEventListener("click", function () {
				closeModal(modal);
			});
		}
	});
}
