import $ from "jquery";
import "slick-carousel";

export default function () {
	const integrations = [...document.querySelectorAll(".integrations")];

	integrations.forEach((integration) => {
		const $slideContainer = $(integration.querySelector(".js-slidesContainer"));

		const nextButton = integration.querySelector(".js-nextButton");
		const prevButton = integration.querySelector(".js-previousButton");

		const slickOptions = {
			slidesToShow: 3.75,
			slidesToScroll: 1,
			infinite: true,
			centerMode: false,
			centerPadding: 20,
			nextArrow: nextButton,
			prevArrow: prevButton,
		};

		$slideContainer.on("init afterChange", function (event, slick) {
			updateButtons(slick);
		});

		function initSlick() {
			if ($(window).width() < 1024) {
				if ($slideContainer.hasClass("slick-initialized")) {
					$slideContainer.slick("unslick");
				}
				return;
			}

			if (!$slideContainer.hasClass("slick-initialized")) {
				return $slideContainer.slick(slickOptions);
			}
		}

		function updateButtons(instance) {
			if (instance.options.infinite) return;
			var slide = instance.currentSlide;
			slide === 0 ? prevButton.classList.add("disabled") : prevButton.classList.remove("disabled");
			slide === instance.slideCount - instance.options.slidesToShow
				? nextButton.classList.add("disabled")
				: nextButton.classList.remove("disabled");
		}

		$(window).on("resize", initSlick);

		initSlick();
	});
}
