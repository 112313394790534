export default function () {
	const accordions = [...document.querySelectorAll(".js-accordion")];

	const Accordion = function (accordionElement) {
		const numberToShow = parseInt(accordionElement.dataset.show, 10);

		// // Bail early if showing all by default
		// if (!accordionNumberToShow || !accordionShowMore) return;

		// DOM Elements
		this.els = {
			accordion: accordionElement,
			accordionCards: [...accordionElement.children],
			showMoreButton: accordionElement.parentElement.querySelector(".js-accordionShowMore"),
		};

		// Widget state
		this.options = {
			numberToShow: Number.isNaN(numberToShow) ? 0 : numberToShow,
			showMoreIncrement: 3,
			showMoreButtonIsVisible: numberToShow && numberToShow < this.els.accordionCards.length,
			maxHeight: "none",
			// Offset to take next card box shadow into account
			verticalOffset: 4,
		};
	};

	Accordion.prototype.updateHeight = function () {
		const maxH = this.els.accordionCards.reduce((total, currentEl, idx) => {
			// Get first element heights, including vertical margins
			if (idx < this.options.numberToShow) {
				const computedStyles = window.getComputedStyle(currentEl);
				const verticalMargin = parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom);
				const totalHeight = currentEl.offsetHeight + verticalMargin;

				return total + totalHeight;
			}
			return total;
		}, 0);

		this.options.maxHeight = maxH - this.options.verticalOffset;
		this.els.accordion.style.maxHeight = this.options.maxHeight + "px";
	};

	Accordion.prototype.init = function () {
		// Expanding cards
		this.els.accordionCards.forEach((card) => {
			const trigger = card.querySelector(".js-accordionCardTrigger");

			trigger &&
				trigger.addEventListener("click", () => {
					card.classList.toggle("active");
					this.updateHeight();
				});
		});

		// Show more button
		this.els.showMoreButton &&
			this.els.showMoreButton.addEventListener("click", () => {
				this.options.numberToShow += this.options.showMoreIncrement;
				if (this.options.numberToShow >= this.els.accordionCards.length) {
					this.options.maxHeight = "none";
					this.els.accordion.style.maxHeight = this.options.maxHeight;

					this.options.showMoreButtonIsVisible = false;
					this.els.showMoreButton.style.display = "none";
				} else {
					this.updateHeight();
				}
			});

		window.addEventListener("resize", () => {
			this.updateHeight();
		});

		this.updateHeight();
	};

	// Initialise Accordion widget
	accordions.forEach((acc) => {
		const accordion = new Accordion(acc);
		accordion.init();
	});

	// function setAccordionHeight(accordion, accordionCards, accordionNumberToShow) {
	// 	// To hide the next hidden card's box-shadow
	// 	const offset = 4;

	// 	const maxH = accordionCards.reduce((total, currentEl, idx) => {
	// 		// Get first element heights, including vertical margins
	// 		if (idx < accordionNumberToShow) {
	// 			const computedStyles = window.getComputedStyle(currentEl);
	// 			const verticalMargin = parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom);
	// 			const totalHeight = currentEl.offsetHeight + verticalMargin;

	// 			return total + totalHeight;
	// 		} else {
	// 			return total;
	// 		}
	// 	}, 0);
	// 	accordion.style.maxHeight = maxH - offset + "px";
	// }
}
