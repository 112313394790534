import $ from "jquery";
import "slick-carousel";

export default function testimonialsInit() {
	const testimonials = document.querySelectorAll(".testimonials");

	testimonials.forEach((testimonial) => {
		const slideContainer = testimonial.querySelector(".js-slidesContainer");
		const nextButton = testimonial.querySelector(".js-nextButton");
		const prevButton = testimonial.querySelector(".js-previousButton");

		$(slideContainer).on("init afterChange", function (event, slick) {
			updateButtons(slick);
		});

		const slickInstance = $(slideContainer).slick({
			slidesToShow: 3.75,
			slidesToScroll: 1,
			infinite: true,
			centerMode: true,
			centerPadding: 20,
			nextArrow: nextButton,
			prevArrow: prevButton,
			responsive: [
				{
					breakpoint: 1536,
					settings: {
						slidesToShow: 3.25,
					},
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1.1,
						infinite: false,
					},
				},
			],
		});

		function updateButtons(instance) {
			if (instance.options.infinite) return;
			var slide = instance.currentSlide;
			slide === 0 ? prevButton.classList.add("disabled") : prevButton.classList.remove("disabled");
			slide === instance.slideCount - instance.options.slidesToShow
				? nextButton.classList.add("disabled")
				: nextButton.classList.remove("disabled");
		}
	});
}
