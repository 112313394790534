import videoLightboxInit from "./videoLightbox";
import testimonialsInit from "./testimonials";
import expanderInit from "./expander";
import accordionInit from "./accordion";
import integrationsInit from "./integrations";
import tooltipInit from "./tooltip";
import pricingCardsInit from "./pricing";
import blogListingInit from "./blog-listing";
import newsInit from "./news";
import benefitsInit from "./benefits";
import modalInit from "./modal";
import marketoFormsInit from "./marketoForms";
import bannerAnimationInit from "./banner-animation";

window.addEventListener("load", function () {
	videoLightboxInit();

	testimonialsInit();

	expanderInit();

	accordionInit();

	integrationsInit();

	tooltipInit();

	pricingCardsInit();

	blogListingInit();

	newsInit();

	benefitsInit();

	modalInit();

	marketoFormsInit();

	bannerAnimationInit();
});
