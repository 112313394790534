// For widgets that hide some of the items, e.g. feature list only shows 2 items on mobile
export default function () {
	const DEFAULT_SHOW_ITEMS = 2;
	// Create a condition that targets viewports at least 1024px wide
	const mediaQuery = window.matchMedia("(min-width: 1024px)");

	// Setting max height to only show first 2 elements on mobile (incl. margin so no cut-offs)
	function handleDesktopChange(e, expander) {
		if (e.matches) {
			// Now in desktop, clear max height
			expander.style.maxHeight = "none";
		} else if (!expander.isExpanded) {
			// Now in mobile/tablet & hasn't already been expanded

			// Max height calculated when in mobile/tablet size
			if (!expander.maxH) {
				setExpanderMaxH(expander);
			}
			// Set max height cutoff
			expander.style.overflow = "hidden";
			expander.style.maxHeight = expander.maxH + "px";
		}
	}

	function setExpanderMaxH(expander) {
		const showNumItems = expander.dataset.showItems || DEFAULT_SHOW_ITEMS;

		const selector = expander.dataset.itemSelector;
		const gridItems = [...expander.children];

		const maxH = gridItems.reduce((total, currentEl, idx) => {
			// Get first 2 elements heights, including vertical margins
			if (idx < showNumItems) {
				const computedStyles = window.getComputedStyle(currentEl);
				const verticalMargin = parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom);
				const totalHeight = currentEl.offsetHeight + verticalMargin;

				return total + totalHeight;
			}
			return total;
		}, 0);

		// Set as easily accessible property on the expanded,
		// reducing maxH to avoid top of next element
		expander.maxH = maxH - 10;
	}

	// Generic expander trigger for feature grid, integrations list etc.
	const triggers = document.querySelectorAll(".js-expanderTrigger");

	if (!triggers) return;

	triggers.forEach((trigger) => {
		// Assuming one expander per widget, widgets root element is always a <section>
		const expander = trigger.closest("section").querySelector(".js-expander");
		if (expander) {
			const showItems = expander.dataset.showItems || DEFAULT_SHOW_ITEMS;
			// Early bail out if not enough items
			if (expander.children.length < showItems) {
				trigger.classList.add("hidden");
				return;
			}

			// Continue, add listeners
			trigger.addEventListener("click", function () {
				trigger.classList.add("hidden");
				expander.style.maxHeight = "none";
				expander.isExpanded = true;
			});

			mediaQuery.addEventListener("change", function (e) {
				handleDesktopChange(e, expander);
			});

			// Initial check
			handleDesktopChange(mediaQuery, expander);
		} else {
			console.log("Could not find expanding element for", trigger);
		}
	});
}
