import { createPopper } from "@popperjs/core";
// import { createPopper } from "@popperjs/core/lib/popper-lite";
// import flip from "@popperjs/core/lib/modifiers/flip";
// import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";

export default function () {
	const tooltipTrigger = [...document.querySelectorAll(".js-tooltipTrigger")];
	const modifiers = [
		{
			name: "offset",
			options: {
				offset: [0, 10],
			},
		},
	];

	tooltipTrigger.forEach((trigger) => {
		const tooltip = trigger.parentElement.querySelector(".js-tooltipContent");

		const popperInstance = createPopper(trigger, tooltip, {
			placement: "right",
			modifiers,
		});

		function show() {
			// Make the tooltip visible
			tooltip.setAttribute("data-show", "");

			// Enable the event listeners
			popperInstance.setOptions({
				modifiers: [...modifiers, { name: "eventListeners", enabled: true }],
			});

			// Update its position
			popperInstance.update();
		}

		function hide() {
			// Hide the tooltip
			tooltip.removeAttribute("data-show");

			// Disable the event listeners
			popperInstance.setOptions({
				modifiers: [...modifiers, { name: "eventListeners", enabled: false }],
			});
		}

		const showEvents = ["mouseenter", "focus"];
		const hideEvents = ["mouseleave", "blur"];

		showEvents.forEach((event) => {
			trigger.addEventListener(event, show);
		});

		hideEvents.forEach((event) => {
			trigger.addEventListener(event, hide);
		});
	});
}
