export default function () {
	const videoPlayerTriggers = [...document.querySelectorAll(".js-videoLightboxTrigger")];

	videoPlayerTriggers.forEach((trigger) => {
		const video = trigger.querySelector("video");
		const videoSrc = video.src;
		if (!videoSrc) return;

		trigger.addEventListener("click", function () {
			const lightbox = getOrCreateLightbox(videoSrc);

			lightbox.classList.remove("hidden");
		});
	});

	function getOrCreateLightbox(videoSource) {
		let lightbox = document.querySelector("js-lightbox");
		if (!lightbox) {
			// Create lightbox
			const lb = document.createElement("div");
			lb.classList.add("js-lightbox", "lightbox", "hidden");

			const videoContainer = document.createElement("div");
			videoContainer.classList.add("lightbox__video-container");

			// Close button
			const closeBtn = document.createElement("button");
			closeBtn.type = "button";
			closeBtn.classList.add("lightbox__close-button");

			const video = document.createElement("video");

			video.autoplay = true;
			video.controls = true;

			document.body.appendChild(lb);
			lb.appendChild(videoContainer);
			lb.appendChild(closeBtn);
			videoContainer.appendChild(video);

			function closeLightbox(video, lightbox) {
				video.pause();
				lightbox.classList.add("hidden");
			}

			// Add Listeners
			closeBtn.addEventListener("click", function () {
				closeLightbox(video, lb);
			});

			lb.addEventListener("click", function (e) {
				if (e.target === lb) {
					closeLightbox(video, lb);
				}
			});

			lightbox = lb;
		}
		const video = lightbox.querySelector("video");
		if (video.src !== videoSource) {
			video.src = videoSource;
		}

		return lightbox;
	}
}
