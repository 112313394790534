export default function () {
	const filterSelected = document.querySelector(".js-filterSelected");
	const filterOptions = document.querySelector(".js-filterOptions");

	if (!filterSelected || !filterOptions) return;

	const revealOptionsCheckbox = document.querySelector(".js-revealOptions");
	const closeRevealOptions = document.querySelector(".js-closeRevealOptions");

	// Change selected text dropdown on mobile
	const filterOptionsLabels = filterOptions.querySelectorAll("label");
	filterOptionsLabels.forEach((label) => {
		label.addEventListener("click", function (e) {
			const labelText = e.target.textContent;
			if (labelText) {
				filterSelected.textContent = labelText;
			}
		});
	});

	// Ok button
	closeRevealOptions.addEventListener("click", function () {
		revealOptionsCheckbox.checked = false;
	});
}
