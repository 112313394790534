import $ from "jquery";
import "slick-carousel";

export default function () {
	const newsWidgets = document.querySelectorAll(".js-news");

	newsWidgets.forEach((widget) => {
		const carousel = widget.querySelector(".js-newsCarousel");
		if (!carousel) return;
		const carouselContainer = carousel.parentElement;
		const slideContainer = $(carousel);
		const nextButton = widget.querySelector(".js-nextButton");
		const prevButton = widget.querySelector(".js-previousButton");

		slideContainer.on("init afterChange", function (event, slick) {
			updateButtons(slick);
		});

		const slickInstance = slideContainer.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: false,
			centerMode: false,
			nextArrow: nextButton,
			prevArrow: prevButton,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1.1,
					},
				},
			],
		});

		function updateButtons(instance) {
			if (instance.options.infinite) return;
			var slide = instance.currentSlide;

			if (slide === 0) {
				prevButton.classList.add("disabled");
				carouselContainer.classList.add("hide-after");
			} else {
				prevButton.classList.remove("disabled");
				carouselContainer.classList.remove("hide-after");
			}

			if (slide === instance.slideCount - instance.options.slidesToShow) {
				nextButton.classList.add("disabled");
				carouselContainer.classList.add("hide-before");
			} else {
				nextButton.classList.remove("disabled");
				carouselContainer.classList.remove("hide-before");
			}
		}
	});
}
