const { MktoForms2 } = window;

export default function () {
	function initSelectFields() {
		const selects = [...document.querySelectorAll("select.mktoField")];
		selects.forEach((select) => {
			const parentEl = select.parentElement;
			parentEl.classList.add("field-container");

			const fieldWrapper = document.createElement("div");
			fieldWrapper.classList.add("field-select");

			// Insert wrapper, move select inside wrapper
			parentEl.appendChild(fieldWrapper);
			fieldWrapper.appendChild(select);
			select.classList.add("field-select");
		});
	}

	function initRadioAndCheckboxes() {
		const inputs = [...document.querySelectorAll('input[type="radio"].mktoField, input[type="checkbox"].mktoField')];
		inputs.forEach((input) => {
			const parentEl = input.parentElement;
			parentEl.classList.add("field-container");

			// Changes to label for custom Radio/Checkbox elements
			const label = parentEl.querySelector("label");
			const labelText = label.innerHTML;
			label.innerHTML = "";
			const span = document.createElement("span");
			span.innerHTML = labelText;
			label.appendChild(input);
			label.append(span);

			label.classList.add("field-radio");
		});
	}

	function initButtons() {
		const buttons = [...document.querySelectorAll(".mktoButton")];
		buttons.forEach((btn) => {
			btn.classList.remove("mktoButton");
			btn.classList.add("btn", "btn--primary");
		});
	}

	function initForms() {
		const forms = [...document.querySelectorAll("form[data-formid][data-program]")];
		forms.forEach((form) => {
			// Load Marketo forms
			MktoForms2.loadForm("//pages.theaccessgroup.com", form.getAttribute("data-program"), form.getAttribute("data-formid"), function () {
				// Forms elements loaded onto page and can be manipulated

				initSelectFields();

				initRadioAndCheckboxes();

				initButtons();
			});
		});
	}

	initForms();
}
