import $ from "jquery";
export default function () {
	const HIDDEN_CLASS = "hidden";

	// Pricing tiers bullet list details
	const pricingCards = document.querySelectorAll(".js-pricingCard");

	pricingCards.forEach((card) => {
		const showPricingDetailsButton = card.querySelector(".js-showPricingDetails");
		const hidePricingDetailsButton = card.querySelector(".js-hidePricingDetails");
		const pricingDetails = card.querySelector(".js-pricingDetails");

		if (!pricingDetails) return;

		showPricingDetailsButton.addEventListener("click", function () {
			pricingDetails.classList.remove(HIDDEN_CLASS);
			showPricingDetailsButton.classList.add(HIDDEN_CLASS);
			hidePricingDetailsButton.classList.remove(HIDDEN_CLASS);
		});
		hidePricingDetailsButton.addEventListener("click", function () {
			pricingDetails.classList.add(HIDDEN_CLASS);
			showPricingDetailsButton.classList.remove(HIDDEN_CLASS);
			hidePricingDetailsButton.classList.add(HIDDEN_CLASS);
		});
	});

	// Pricing plan comparison table (only visible on desktop)
	const pricingSections = document.querySelectorAll(".js-pricing");

	pricingSections.forEach((section) => {
		const showPricingTableButton = section.querySelector(".js-showPricingTable");

		// Multiple hide buttons (top and bottom of table)
		const hidePricingTableButtons = section.querySelectorAll(".js-hidePricingTable");
		const pricingTable = section.querySelector(".js-pricingTable");

		if (!pricingTable) return;

		showPricingTableButton.addEventListener("click", function () {
			showPricingTableButton.classList.add(HIDDEN_CLASS);
			hidePricingTableButtons.forEach((btn) => {
				btn.classList.remove(HIDDEN_CLASS);
			});

			pricingTable.classList.remove(HIDDEN_CLASS);
		});

		hidePricingTableButtons.forEach((btn) => {
			btn.addEventListener("click", function () {
				showPricingTableButton.classList.remove(HIDDEN_CLASS);
				hidePricingTableButtons.forEach((btn) => {
					btn.classList.add(HIDDEN_CLASS);
				});

				pricingTable.classList.add(HIDDEN_CLASS);
			});
		});

		// Detect when the header is sticky positioned
		const tableHeader = section.querySelector(".js-tableHeader");
		$(window).on("scroll", function () {
			tableHeader.classList.toggle("is-pinned", tableHeader.getBoundingClientRect().top === 104);
		});

		// Set most popular class on associated table cells based on the table header with the most-popular class
		const mostPopularHeader = tableHeader.querySelector(".table-header--most-popular");
		const tableIndex = [...mostPopularHeader.parentElement.children].indexOf(mostPopularHeader);

		if (tableIndex !== -1) {
			pricingTable
				.querySelectorAll(`table td:nth-child(${tableIndex + 1})`)
				.forEach((td) => td.classList.add("most-popular"));
		}
	});
}
