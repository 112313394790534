export default function () {
	const benefits = document.querySelectorAll(".js-benefits");
	const observerOptions = { threshold: 0.5 };

	benefits.forEach((benefitWidget) => {
		const numberCounters = [...benefitWidget.querySelectorAll(".js-numberCounter")];
		numberCounters.forEach((counter) => {
			const target = parseFloat(counter.dataset.targetValue);

			// Take ~3 seconds @ 60fps
			const counterStep = target / 3 / 60;

			// Formatting numbers
			const wholeNumberOnly = target.toString(10).split(".").length === 1;
			const decimalPlaces = wholeNumberOnly ? 0 : target.toString(10).split(".")[1].split("").length;

			// Properties used for animation
			counter.data = {
				target,
				counterStep,
				wholeNumberOnly,
				decimalPlaces,
				val: 0,
				finished: false,
			};
		});

		// Setup observer for widget to only start animation when in view
		const observer = new IntersectionObserver(function (entries, observer) {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					requestAnimationFrame(count);
					observer.disconnect();
				}
			});
		}, observerOptions);

		observer.observe(benefitWidget);

		// Update all counters in widget in one animation frame request
		function count() {
			// Filter out finished counters
			const remainingCounters = numberCounters.filter((counter) => !counter.data.finished);
			if (remainingCounters.length > 0) {
				remainingCounters.forEach((counter) => {
					// Update counter by step amount, capped at target
					counter.data.val = Math.min(counter.data.val + counter.data.counterStep, counter.data.target);
					counter.textContent = counter.data.val.toFixed(counter.data.decimalPlaces);

					// Set finished status
					counter.data.finished = counter.data.val === counter.data.target;
				});

				requestAnimationFrame(count);
			}
		}
	});
}
